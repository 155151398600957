
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup: function() {
    const store = useStore();
    const router = useRouter();
    onBeforeMount(() => {
      if (JwtService.getToken()) {
        router.push({ name: "flowmeters" });
      }
    });


    const submitButton = ref<HTMLElement | null>(null);
    const searchButton = ref<HTMLElement | null>(null);



    //Create form validation object
    const login = Yup.object().shape({
      password: Yup.string()
        .label("Password")
    });

    //console.log(login);



    //Form submit function
    const onSubmitSearch = values => {
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (searchButton.value) {
        // Activate indicator
        searchButton.value!.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      console.log(values);
      Swal.fire({
        text: "Sayğac reestrinə yönləndirilirsiniz...",
        icon: "success",
        timer: 1000,
        buttonsStyling: false,
        confirmButtonText: "Yönləndirmək",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary"
        }
      }).then(function() {
        //console.log(!!JwtService.getToken());
        // Go to page after successfully login
        router.push({ name: "fm-view", params: { assetId: values.fmNumber }});
      });

      //Deactivate indicator
      searchButton.value?.removeAttribute("data-kt-indicator");
    };

    //Form submit function
    const onSubmitLogin = values => {
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value!.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      console.log(values);
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "Siz müvəffəqiyyətlə sistemə daxil oldunuz. Sayğac reestrinə yönləndirilirsiniz...",
            icon: "success",
            timer: 1000,
            buttonsStyling: false,
            confirmButtonText: "Yönləndirmək",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            //console.log(!!JwtService.getToken());
            // Go to page after successfully login
            router.push({ name: "flowmeters" });
          });
        })
        .catch(() => {
          Swal.fire({
            //text: store.getters.getErrors[0],
            text: "Şifrə yanlışdır. Yenidən cəhd edin.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Yenidən yoxlamaq",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      onSubmitSearch,
      login,
      submitButton,
      searchButton
    };
  }
});
