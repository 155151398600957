<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img
          alt="Logo"
          src="media/logos/polarus.png"
          class="h-55px"
        />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100"
          id="kt_login_search_form"
          @submit="onSubmitSearch"
          :validation-schema="search"
          autocomplete="off"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-primary mb-3">
              Sayğac nömrəsi üzrə axtarış
            </h1>
            <!--end::Title-->
          </div>
          <!--begin::Heading-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0"
              >Sayğac nömrəsi</label
              >
              <!--end::Label-->

            </div>
            <!--end::Wrapper-->

            <!--begin::Input-->
            <Field
                id="fmNumber"
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="fmNumber"
                autocomplete="off"
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="fmNumber" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
                type="submit"
                ref="searchButton"
                id="kt_search_submit"
                class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">
                Axtarış
              </span>

              <span class="indicator-progress">
                Gözləyin...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Submit button-->
          </div>
          <!--end::Actions-->
        </Form>
        <Form
            class="form w-100"
            id="kt_login_signin_form"
            @submit="onSubmitLogin"
            :validation-schema="login"
            autocomplete="off"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h3 class="text-dark mb-3">
              və ya
            </h3>
            <!--end::Title-->
            <!--begin::Title-->
            <h1 class="text-primary mb-3">
              Sistemə giriş
            </h1>
            <!--end::Title-->
          </div>
          <!--begin::Heading-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0"
                >Şifrə</label
              >
              <!--end::Label-->

            </div>
            <!--end::Wrapper-->

            <!--begin::Input-->
            <Field
              id="password"
              class="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autocomplete="off"
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">
                Davam etmək
              </span>

              <span class="indicator-progress">
                Gözləyin...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Submit button-->
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6 d-none">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup: function() {
    const store = useStore();
    const router = useRouter();
    onBeforeMount(() => {
      if (JwtService.getToken()) {
        router.push({ name: "flowmeters" });
      }
    });


    const submitButton = ref<HTMLElement | null>(null);
    const searchButton = ref<HTMLElement | null>(null);



    //Create form validation object
    const login = Yup.object().shape({
      password: Yup.string()
        .label("Password")
    });

    //console.log(login);



    //Form submit function
    const onSubmitSearch = values => {
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (searchButton.value) {
        // Activate indicator
        searchButton.value!.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      console.log(values);
      Swal.fire({
        text: "Sayğac reestrinə yönləndirilirsiniz...",
        icon: "success",
        timer: 1000,
        buttonsStyling: false,
        confirmButtonText: "Yönləndirmək",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary"
        }
      }).then(function() {
        //console.log(!!JwtService.getToken());
        // Go to page after successfully login
        router.push({ name: "fm-view", params: { assetId: values.fmNumber }});
      });

      //Deactivate indicator
      searchButton.value?.removeAttribute("data-kt-indicator");
    };

    //Form submit function
    const onSubmitLogin = values => {
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value!.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      console.log(values);
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "Siz müvəffəqiyyətlə sistemə daxil oldunuz. Sayğac reestrinə yönləndirilirsiniz...",
            icon: "success",
            timer: 1000,
            buttonsStyling: false,
            confirmButtonText: "Yönləndirmək",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            //console.log(!!JwtService.getToken());
            // Go to page after successfully login
            router.push({ name: "flowmeters" });
          });
        })
        .catch(() => {
          Swal.fire({
            //text: store.getters.getErrors[0],
            text: "Şifrə yanlışdır. Yenidən cəhd edin.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Yenidən yoxlamaq",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      onSubmitSearch,
      login,
      submitButton,
      searchButton
    };
  }
});
</script>
